import * as AuthSDK from "@maccuaa/intellitrust-auth-sdk";
import type {
  FIDORegisterChallengeRegistrationAuthenticatorAttachmentEnum,
  FIDORegisterChallengeRegistrationRequireResidentKeyEnum,
  FIDORegisterChallengeRegistrationUserVerificationEnum,
} from "@maccuaa/intellitrust-auth-sdk";
import { type Theme as MuiTheme, createTheme } from "@mui/material";
import { startRegistration } from "@simplewebauthn/browser";
import type { PublicKeyCredentialCreationOptionsJSON } from "@simplewebauthn/types";
import { proxy, subscribe } from "valtio";
import { getRandomCardNumber, getRandomNumber, getRandomPin } from "../lib/card";
import { type Theme, themes } from "../theme";
import { errorHandler } from "./MessageStore";

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    monospace: true;
  }
}

export type CARD_TYPE = "checking" | "savings";
export type BIOMETRIC_PLATFORM = "web" | "mobile";
export type AUTHENTICATION_FLOW = "redirect" | "popup";

const LOCAL_STORAGE_KEY = "bank_poc";

const {
  VITE_IDAAS_BIOMETRIC_APP_ID,
  VITE_IDAAS_PUSH_APP_ID,
  VITE_IDAAS_URL,
  VITE_FULL_WORKFLOW_ID,
  VITE_IDAAS_LOGIN_APP_ID,
} = import.meta.env;

const authSdk = new AuthSDK.API({ basePath: VITE_IDAAS_URL });

const wait = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

interface Token {
  id: string;
  platform: string;
  serialNumber: string;
  state: string;
  type: string;
}

export interface UserProfile {
  firstName: string;
  lastName: string;
  userId: string;
  email: string;
  tokens: Token[];
}

export interface VirtualCard {
  nickname: string;
  cardNumber: string;
  numOfDays: number;
  limit: number;
}

export interface Account {
  type: CARD_TYPE;
  balance: number;
  number: string;
  pin: string;
  virtualCards: VirtualCard[];
}

export interface WebhookFIDORegisterChallenge {
  challenge: string;
  registration_authenticator_attachment: FIDORegisterChallengeRegistrationAuthenticatorAttachmentEnum;
  registration_require_resident_key: FIDORegisterChallengeRegistrationRequireResidentKeyEnum;
  registration_user_verification: FIDORegisterChallengeRegistrationUserVerificationEnum;
  rp_name: string;
  user_display_name: string;
  registered_credentials: string;
  email_address: string;
  user_id: string;
}

export interface UserProviderStore {
  accounts: Account[];
  user?: UserProfile;
  transactionId?: string;
  enrollmentId?: string;
  isUniversityTheme: () => boolean;
  documentMediaIds?: DocumentMediaIds[];
  applicantId?: string;

  // App Settings
  registrationWorkflowId: string;
  biometricStepUp: BIOMETRIC_PLATFORM;
  authenticationFlow: AUTHENTICATION_FLOW;
  theme: Theme;
  performCreditCheck: boolean;
  setTheme: (name: string) => void;
  loadTheme: () => MuiTheme;
  setPerformCreditCheck: (doCreditCheck: boolean) => void;
  resetSettings: () => void;

  // Account Management
  addAccount: (type: CARD_TYPE, randomBalance?: boolean) => void;
  addVirtualCard: (nickname: string, numOfDays: number, limit: number, cardNumber: string, type: CARD_TYPE) => void;
  removeVirtualCard: (virtualCard: VirtualCard, type: CARD_TYPE) => void;
  updateEmail: (newEmail: string) => void;
  resetAccounts: () => void;
  changePin: (pin: string, type: CARD_TYPE) => void;
  sendFunds: (from: CARD_TYPE, amount: number) => void;
  transferFunds: (from: CARD_TYPE, to: CARD_TYPE, amount: number) => void;
  fundAccount: (account: CARD_TYPE, amount: number) => void;
  fetchProfileInfo: (userId: string) => Promise<void>;
  fetchTokens: (userId: string) => Promise<Token[]>;
  addUserInfo: (userInfo?: UserProfile) => void;
  setTransactionId: (transactionId: string) => void;

  // IIDaaS Values
  setEnrollmentId: (enrollmentId: string | undefined) => void;
  setDocumentMediaIds: (mediaIds: DocumentMediaIds[] | undefined) => void;
  setApplicantId: (applicantId: string | undefined) => void;

  // IDaaS Authentication
  getPasskeyChallenge: () => Promise<AuthSDK.AuthenticatedResponse>;
  startFidoLogin: (email: string) => Promise<AuthSDK.AuthenticatedResponse>;
  completeFidoLogin: (
    authToken: string,
    fidoResponse: AuthSDK.FIDOResponse,
    authenticator: "FIDO" | "PASSKEY",
  ) => Promise<boolean>;
  startBiometricAuth: () => Promise<AuthSDK.AuthenticatedResponse | null>;
  finishBiometricAuth: (authResponse: AuthSDK.AuthenticatedResponse, signal: AbortSignal) => Promise<boolean>;
  startTransaction: (details: AuthSDK.TransactionDetail[]) => Promise<AuthSDK.AuthenticatedResponse | null>;
  finishTransaction: (
    authResponse: AuthSDK.AuthenticatedResponse,
    transactionDetails: AuthSDK.TransactionDetail[],
    signal: AbortSignal,
  ) => Promise<boolean>;

  // IDaaS Registration
  completeFidoRegistration: (
    fidoRegisterChallenge: WebhookFIDORegisterChallenge,
  ) => Promise<AuthSDK.FIDOToken | undefined>;

  // Session Management
  logout: () => void;
  isLoggedIn: () => boolean;
  hasActiveToken: (tokens?: Token[]) => boolean;
}

const initialStateRaw = localStorage.getItem(LOCAL_STORAGE_KEY) ?? "{}";
const initialState = JSON.parse(initialStateRaw) as {
  accounts?: Account[];
  user?: UserProfile;
  isUniversityTheme?: () => boolean;
};

// special handling to fix a bug Andrew made which causes the UI to crash
// from commit 9296e8459dc7b939363f30b4f7fe5c5467460ff0
if (initialState.isUniversityTheme !== undefined) {
  // biome-ignore lint/performance/noDelete: setting to undefined does not work
  delete initialState.isUniversityTheme;
}

export const userStore: UserProviderStore = proxy<UserProviderStore>({
  accounts: [],
  user: undefined,
  registrationWorkflowId: VITE_FULL_WORKFLOW_ID,
  biometricStepUp: "web",
  authenticationFlow: "redirect",
  theme: themes.bankOfEntrust,
  performCreditCheck: false,
  enrollmentId: "",
  isUniversityTheme: () => {
    return userStore.theme.name === "Entrust University";
  },
  documentMediaIds: undefined,
  applicantId: undefined,
  setTheme: (name: string) => {
    if (themes[name]) {
      const theme = themes[name];
      userStore.theme = theme;
    }
  },
  loadTheme: () => {
    const { theme } = userStore;
    // Set the tab title
    document.title = theme.name;
    return createTheme({
      palette: {
        primary: {
          main: theme.primary,
        },
        secondary: {
          main: theme.secondary,
        },
      },
      shape: {
        borderRadius: 0,
      },
      components: {
        MuiTypography: {
          defaultProps: {
            variantMapping: {
              monospace: "code",
            },
          },
        },
      },
    });
  },
  setPerformCreditCheck: (doCreditCheck) => {
    userStore.performCreditCheck = doCreditCheck;
  },
  resetSettings: () => {
    localStorage.clear();
    window.location.pathname = "/";
  },

  addAccount: (type, randomBalance = false) => {
    const balance = randomBalance ? getRandomNumber(1000, 10000) : 0;
    const number = getRandomCardNumber();
    const pin = getRandomPin();
    userStore.accounts.push({ type, balance, number, pin, virtualCards: [] });
  },
  addVirtualCard: (nickname, numOfDays, limit, cardNumber, type) => {
    const index = userStore.accounts.findIndex((a) => a.type === type);
    if (!userStore.accounts[index].virtualCards) {
      userStore.accounts[index].virtualCards = [];
    }
    userStore.accounts[index].virtualCards.push({ nickname, numOfDays, limit, cardNumber });
  },
  removeVirtualCard: (virtualCard, type) => {
    const index = userStore.accounts.findIndex((a) => a.type === type);
    const cardIndex = userStore.accounts[index].virtualCards.findIndex((a) => a.nickname === virtualCard.nickname);

    if (cardIndex > -1) {
      userStore.accounts[index].virtualCards.splice(cardIndex, 1);
    }
  },
  updateEmail: (email: string) => {
    if (userStore.user && email) {
      userStore.user.email = email;
    }
  },
  resetAccounts: () => {
    userStore.accounts = [];
  },
  transferFunds: (from: CARD_TYPE, to: CARD_TYPE, amount: number) => {
    const fromIndex = userStore.accounts.findIndex((a) => a.type === from);
    const toIndex = userStore.accounts.findIndex((a) => a.type === to);

    const fromBalance = userStore.accounts[fromIndex].balance;
    const toBalance = userStore.accounts[toIndex].balance;

    userStore.accounts[fromIndex].balance = fromBalance - amount;
    userStore.accounts[toIndex].balance = toBalance + amount;
  },
  fundAccount: (account: CARD_TYPE, amount: number) => {
    const index = userStore.accounts.findIndex((a) => a.type === account);

    const balance = userStore.accounts[index].balance;

    userStore.accounts[index].balance = balance + amount;
  },
  fetchProfileInfo: async (userId: string) => {
    const response = await fetch(`/api/user/${userId}`);

    const data = await response.json();

    userStore.user = {
      firstName: data.firstName ?? "",
      lastName: data.lastName ?? "",
      userId: data.userId ?? "",
      email: data.userId ?? "",
      tokens: data.tokens ?? [],
    };
  },
  fetchTokens: async (userId: string) => {
    const response = await fetch(`/api/user/${userId}`);

    const data = await response.json();

    return data.tokens ?? [];
  },
  changePin: (pin: string, type: CARD_TYPE) => {
    const index = userStore.accounts.findIndex((a) => a.type === type);
    userStore.accounts[index].pin = pin;
  },
  sendFunds: (from: CARD_TYPE, amount: number) => {
    const fromIndex = userStore.accounts.findIndex((a) => a.type === from);

    const fromBalance = userStore.accounts[fromIndex].balance;

    userStore.accounts[fromIndex].balance = fromBalance - amount;
  },
  addUserInfo: (userInfo?: UserProfile) => {
    userStore.user = {
      firstName: userInfo?.firstName ?? "",
      lastName: userInfo?.lastName ?? "",
      userId: userInfo?.userId ?? "",
      email: userInfo?.userId ?? "",
      tokens: userInfo?.tokens ?? [],
    };
  },
  setTransactionId: (transactionId) => {
    userStore.transactionId = transactionId;
  },
  setEnrollmentId: (enrollmentId) => {
    userStore.enrollmentId = enrollmentId;
  },
  setDocumentMediaIds(mediaIds) {
    userStore.documentMediaIds = mediaIds;
  },
  setApplicantId(applicantId) {
    userStore.applicantId = applicantId;
  },
  getPasskeyChallenge: async () => {
    const response = await authSdk.userChallengeUsingPOST("PASSKEY", {
      applicationId: VITE_IDAAS_LOGIN_APP_ID,
    });
    return response.data;
  },
  startFidoLogin: async (email: string) => {
    const challengeParams: AuthSDK.UserChallengeParameters = {
      userId: email,
      applicationId: VITE_IDAAS_LOGIN_APP_ID,
    };

    // Initiate fido challenge
    const secondFactorChallengeResponse = await authSdk.userChallengeUsingPOST("FIDO", {
      ...challengeParams,
      origin: location.origin,
      userId: email,
    });

    return secondFactorChallengeResponse.data;
  },
  completeFidoLogin: async (
    authToken: string,
    fidoResponse: AuthSDK.FIDOResponse,
    authenticator: "FIDO" | "PASSKEY",
  ) => {
    const authenticateParams: AuthSDK.UserAuthenticateParameters = {
      applicationId: VITE_IDAAS_LOGIN_APP_ID,

      fidoResponse: fidoResponse,
      enableWebSession: true,
    };

    const {
      data: { authenticationCompleted, userId },
    } = await authSdk.userAuthenticateUsingPOST(authenticator, authenticateParams, authToken);

    if (authenticationCompleted && userId) {
      await userStore.fetchProfileInfo(userId);
      return true;
    }

    return false;
  },
  startBiometricAuth: async () => {
    const challengeParams: AuthSDK.UserAuthenticateQueryParameters = {
      userId: userStore.user?.userId ?? "",
      applicationId: VITE_IDAAS_BIOMETRIC_APP_ID,
    };

    const challengeResponse = await authSdk.userChallengeUsingPOST("FACE", {
      ...challengeParams,
      tokenPushMutualChallengeEnabled: true,
    });

    return challengeResponse.data;
  },
  finishBiometricAuth: async (authResponse: AuthSDK.AuthenticatedResponse, signal: AbortSignal) => {
    const authenticateParams: AuthSDK.UserAuthenticateParameters = {
      applicationId: VITE_IDAAS_BIOMETRIC_APP_ID,
      secondFactorAuthenticator: "FACE",
      newPassword: authResponse.tokenPushMutualChallenge,
      faceResponse: authResponse.faceChallenge?.workflowRunId,
    };

    // Wait for user to complete biometric step up auth
    while (!signal.aborted) {
      const { data } = await authSdk.userAuthenticateUsingPOST("FACE", authenticateParams, authResponse.token, {
        signal,
      });

      if (data.status === "CANCEL" || data.status === "CONCERN") {
        throw new Error("Authentication was cancelled.");
      }

      if (data.status === "CONFIRM") {
        return true;
      }

      await wait(1000);
    }

    return false;
  },
  startTransaction: async (transactionDetails: AuthSDK.TransactionDetail[]) => {
    const challengeParams: AuthSDK.UserAuthenticateQueryParameters = {
      userId: userStore.user?.userId ?? "",
      applicationId: VITE_IDAAS_PUSH_APP_ID,
      transactionDetails,
    };

    const queryResponse = await authSdk.userAuthenticatorQueryUsingPOST({
      ...challengeParams,
    });

    // Low risk: external - Complete authentication
    if (queryResponse.data.authenticationTypes?.includes("EXTERNAL")) {
      const externalResponse = await authSdk.userAuthenticateUsingPOST(
        "EXTERNAL",
        { ...challengeParams },
        queryResponse.data.token,
      );

      if (externalResponse.data.authenticationCompleted) {
        return null;
      }
    }

    const authenticator = queryResponse.data.authenticationTypes?.shift() ?? "FACE";

    const challengeResponse = await authSdk.userChallengeUsingPOST(authenticator, {
      ...challengeParams,
      summary: "Fund Transfer Confirmation",
      transactionDetails,
      tokenPushMutualChallengeEnabled: authenticator === "FACE",
    });

    return challengeResponse.data;
  },
  finishTransaction: async (
    authResponse: AuthSDK.AuthenticatedResponse,
    transactionDetails: AuthSDK.TransactionDetail[],
    signal: AbortSignal,
  ) => {
    const challengeParams: AuthSDK.UserAuthenticateParameters = {
      userId: userStore.user?.userId,
      applicationId: VITE_IDAAS_PUSH_APP_ID,
      transactionDetails,
      newPassword: authResponse.tokenPushMutualChallenge ?? undefined,
      faceResponse: authResponse.faceChallenge?.workflowRunId,
    };

    const { token, tokenChallenge } = authResponse;

    // Wait for user to action push transaction
    while (!signal.aborted) {
      const isTokenPush = (tokenChallenge?.token.length ?? 0) > 0;

      const { data } = await authSdk.userAuthenticateUsingPOST(
        isTokenPush ? "TOKENPUSH" : "FACE",
        challengeParams,
        token,
        { signal },
      );

      if (data.status === "CANCEL" || data.status === "CONCERN") {
        throw new Error("Authentication was cancelled from the mobile device.");
      }

      if (data.status === "CONFIRM") {
        return true;
      }

      await wait(1000);
    }

    return false;
  },
  completeFidoRegistration: async (fidoRegisterChallenge: WebhookFIDORegisterChallenge) => {
    let registeringTokenNum = 1;
    if (fidoRegisterChallenge.registered_credentials) {
      registeringTokenNum = fidoRegisterChallenge.registered_credentials?.split(",").length + 1;
    }

    const tokenCreationOptions: PublicKeyCredentialCreationOptionsJSON = {
      challenge: fidoRegisterChallenge.challenge ?? "",
      rp: {
        name: fidoRegisterChallenge.rp_name ?? "",
      },
      user: {
        displayName: fidoRegisterChallenge.user_display_name ?? "",
        name: fidoRegisterChallenge.email_address,
        id: fidoRegisterChallenge.user_id ?? "",
      },
      pubKeyCredParams: [
        {
          alg: -7, // ES256
          type: "public-key",
        },
        {
          alg: -257, // RS256
          type: "public-key",
        },
      ],
      attestation: "none",
      timeout: 30 * 1000, // 30 seconds
      authenticatorSelection: {
        requireResidentKey: true,
        residentKey: "required",
        userVerification: "required",
      },
    };

    try {
      const registrationResponseJson = await startRegistration(tokenCreationOptions);

      const body: CompletePasskeyRegistration = {
        attestationObject: registrationResponseJson.response.attestationObject,
        clientDataJSON: registrationResponseJson.response.clientDataJSON,
        name: `${userStore.theme.name} ${registeringTokenNum}`,
        userIdStored: true,
        id: atob(fidoRegisterChallenge.user_id ?? ""),
      };

      const response = await fetch("/api/onboarding/passkey", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(body),
      });

      const tokenResponse = (await response.json()) as AuthSDK.FIDOToken;

      return tokenResponse;
    } catch (error) {
      console.error("Failed to complete FIDO registration.", error);
      if (error instanceof Error) {
        errorHandler(error);
      }
    }
  },

  logout: () => {
    userStore.user = undefined;
  },
  isLoggedIn: () => {
    return !!userStore.user;
  },
  hasActiveToken: (tokens?: Token[]) => {
    const t = tokens ?? userStore.user?.tokens ?? [];

    return t.some((t) => t.state === "ACTIVE") ?? false;
  },
  ...initialState,
});

subscribe(userStore, () => {
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(userStore));
  console.log("⚡ Store changed", JSON.stringify(userStore));
});
